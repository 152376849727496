// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".A2pDzH54EBrTTBtYWxVV {\n    display: grid;\n    grid-template-columns: 1fr auto;\n    margin-bottom: var(--spacers-dp24);\n    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);\n    padding: var(--spacers-dp16);\n}\n\n.lKqIyJ4qEPBfbmWDXtyg {\n    margin-bottom: var(--spacers-dp8);\n}\n\n.DGVGl9jyJxx23YqdmLz_ {\n    display: inline-block;\n    font-size: 16px;\n    font-weight: 500;\n    margin: 0;\n    margin-right: var(--spacers-dp8);\n}\n\n.rdSy263T1kDyI4xlxE8y {\n    color: var(--colors-grey800);\n}\n\n.JVjbGo1BqoMKoVHiEcq3 {\n    margin-left: var(--spacers-dp8);\n}\n", "",{"version":3,"sources":["webpack://./src/pages/UserOrganisation/OrganisationUser/OrganisationUser.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,+BAA+B;IAC/B,kCAAkC;IAClC,yCAAyC;IACzC,4BAA4B;AAChC;;AAEA;IACI,iCAAiC;AACrC;;AAEA;IACI,qBAAqB;IACrB,eAAe;IACf,gBAAgB;IAChB,SAAS;IACT,gCAAgC;AACpC;;AAEA;IACI,4BAA4B;AAChC;;AAEA;IACI,+BAA+B;AACnC","sourcesContent":[".container {\n    display: grid;\n    grid-template-columns: 1fr auto;\n    margin-bottom: var(--spacers-dp24);\n    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);\n    padding: var(--spacers-dp16);\n}\n\n.header {\n    margin-bottom: var(--spacers-dp8);\n}\n\n.name {\n    display: inline-block;\n    font-size: 16px;\n    font-weight: 500;\n    margin: 0;\n    margin-right: var(--spacers-dp8);\n}\n\n.email {\n    color: var(--colors-grey800);\n}\n\n.removeButton {\n    margin-left: var(--spacers-dp8);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "A2pDzH54EBrTTBtYWxVV",
	"header": "lKqIyJ4qEPBfbmWDXtyg",
	"name": "DGVGl9jyJxx23YqdmLz_",
	"email": "rdSy263T1kDyI4xlxE8y",
	"removeButton": "JVjbGo1BqoMKoVHiEcq3"
};
export default ___CSS_LOADER_EXPORT___;
