// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".svFLWyAi6hFnvHbbl7Wr {\n    font-size: 14px;\n    font-weight: 500;\n    margin-top: 0;\n    margin-bottom: var(--spacers-dp8);\n}\n\n.gsbtsudqyeFoWzoDwfBv {\n    display: flex;\n    flex-direction: row;\n    align-items: end;\n    justify-content: space-between;\n}\n\n.Ml_MrcKfMtza2UU_LfWG {\n    padding-bottom: var(--spacers-dp16);\n}\n\n.G6iV3VxleFTBcCzTqCLg {\n    margin-bottom: var(--spacers-dp16);\n}\n\n.ejSmL66kIJ0AvgPe7RGq {\n    max-width: 200px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Versions/Filters/Filters.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,gBAAgB;IAChB,aAAa;IACb,iCAAiC;AACrC;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,gBAAgB;IAChB,8BAA8B;AAClC;;AAEA;IACI,mCAAmC;AACvC;;AAEA;IACI,kCAAkC;AACtC;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".subheader {\n    font-size: 14px;\n    font-weight: 500;\n    margin-top: 0;\n    margin-bottom: var(--spacers-dp8);\n}\n\n.filtersWrapper {\n    display: flex;\n    flex-direction: row;\n    align-items: end;\n    justify-content: space-between;\n}\n\n.versionsFilters {\n    padding-bottom: var(--spacers-dp16);\n}\n\n.channelsFilter {\n    margin-bottom: var(--spacers-dp16);\n}\n\n.dhisVersionSelect {\n    max-width: 200px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"subheader": "svFLWyAi6hFnvHbbl7Wr",
	"filtersWrapper": "gsbtsudqyeFoWzoDwfBv",
	"versionsFilters": "Ml_MrcKfMtza2UU_LfWG",
	"channelsFilter": "G6iV3VxleFTBcCzTqCLg",
	"dhisVersionSelect": "ejSmL66kIJ0AvgPe7RGq"
};
export default ___CSS_LOADER_EXPORT___;
