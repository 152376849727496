// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".yuyIpVqHrrnZJZHkmYtH {\n    margin-bottom: var(--spacers-dp16);\n}\n\n.MNvsvFkfWfiYQK2Xk8mc {\n    margin-bottom: var(--spacers-dp8);\n}\n\n.XQPJ7RbA0nirGwsRgQ5w {\n    margin-bottom: var(--spacers-dp16);\n}\n", "",{"version":3,"sources":["webpack://./src/pages/UserOrganisation/Modals/Modal.module.css"],"names":[],"mappings":"AAAA;IACI,kCAAkC;AACtC;;AAEA;IACI,iCAAiC;AACrC;;AAEA;IACI,kCAAkC;AACtC","sourcesContent":[".noticebox {\n    margin-bottom: var(--spacers-dp16);\n}\n\n.form {\n    margin-bottom: var(--spacers-dp8);\n}\n\n.field {\n    margin-bottom: var(--spacers-dp16);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"noticebox": "yuyIpVqHrrnZJZHkmYtH",
	"form": "MNvsvFkfWfiYQK2Xk8mc",
	"field": "XQPJ7RbA0nirGwsRgQ5w"
};
export default ___CSS_LOADER_EXPORT___;
