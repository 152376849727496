// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".bVMfIjc1pQlmR2Etbo8h {\n    display: inline-flex;\n    align-items: center;\n    border: none;\n    background: none;\n}\n\n.bVMfIjc1pQlmR2Etbo8h:focus-visible {\n    outline: none;\n    box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.7);\n}\n\n.bVMfIjc1pQlmR2Etbo8h svg {\n    fill: white !important;\n    margin-left: var(--spacers-dp4);\n}\n", "",{"version":3,"sources":["webpack://./src/components/Header/DropdownButton/DropdownButton.module.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,mBAAmB;IACnB,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,8CAA8C;AAClD;;AAEA;IACI,sBAAsB;IACtB,+BAA+B;AACnC","sourcesContent":[".button {\n    display: inline-flex;\n    align-items: center;\n    border: none;\n    background: none;\n}\n\n.button:focus-visible {\n    outline: none;\n    box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.7);\n}\n\n.button svg {\n    fill: white !important;\n    margin-left: var(--spacers-dp4);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "bVMfIjc1pQlmR2Etbo8h"
};
export default ___CSS_LOADER_EXPORT___;
