// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dz6kv5ls8U8lK50SOXNQ {\n    display: block !important;\n    max-width: 480px;\n    margin: auto;\n    padding: var(--spacers-dp24);\n    text-align: center;\n}\n\n.tmocGxc6DaZklMYI9WZc {\n    margin-top: 0;\n    margin-bottom: var(--spacers-dp24);\n}\n\n.uxCprcBiixgXt_uQqpmh {\n    display: block;\n    margin: var(--spacers-dp8) 0;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/OrganisationInvitation/OrganisationInvitation.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,gBAAgB;IAChB,YAAY;IACZ,4BAA4B;IAC5B,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,kCAAkC;AACtC;;AAEA;IACI,cAAc;IACd,4BAA4B;AAChC","sourcesContent":[".card {\n    display: block !important;\n    max-width: 480px;\n    margin: auto;\n    padding: var(--spacers-dp24);\n    text-align: center;\n}\n\n.explanation {\n    margin-top: 0;\n    margin-bottom: var(--spacers-dp24);\n}\n\n.middle {\n    display: block;\n    margin: var(--spacers-dp8) 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "dz6kv5ls8U8lK50SOXNQ",
	"explanation": "tmocGxc6DaZklMYI9WZc",
	"middle": "uxCprcBiixgXt_uQqpmh"
};
export default ___CSS_LOADER_EXPORT___;
