// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".iDWRX11tQQ21rVotY6U2 {\n    display: flex;\n    height: 72px;\n    width: 72px;\n}\n\n.iDWRX11tQQ21rVotY6U2 img {\n    max-width: 100%;\n    max-height: 100%;\n    object-fit: scale-down;\n}\n", "",{"version":3,"sources":["webpack://./src/components/AppIcon/AppIcon.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,sBAAsB;AAC1B","sourcesContent":[".appIcon {\n    display: flex;\n    height: 72px;\n    width: 72px;\n}\n\n.appIcon img {\n    max-width: 100%;\n    max-height: 100%;\n    object-fit: scale-down;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"appIcon": "iDWRX11tQQ21rVotY6U2"
};
export default ___CSS_LOADER_EXPORT___;
