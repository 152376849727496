// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".D8QmLScwmXItsL1Kydqw {\n    font-size: 16px;\n    font-weight: 500;\n    color: var(--colors-grey800);\n    margin-top: 0;\n    margin-bottom: var(--spacers-dp4);\n}\n\n.d7NEVzHxSMllnCEZR8Ly {\n    margin: 0;\n}\n\n.bGwoskSz1XGdCAtQ_Aec {\n    color: var(--colors-blue700);\n    text-decoration: underline;\n}\n\n.bGwoskSz1XGdCAtQ_Aec:hover,\n.bGwoskSz1XGdCAtQ_Aec:focus {\n    color: var(--colors-grey900);\n}\n\n.XsYdjVGgOyl6KjyAUZxv {\n    display: none;\n}\n\n.I1RJte0wWHAcrvgbR8Ec {\n    margin-top: var(--spacers-dp16);\n}\n\n.s93SqPAWS9343Zbqu91F {\n    margin-top: var(--spacers-dp8);\n}\n", "",{"version":3,"sources":["webpack://./src/pages/UserApp/ScreenshotsCard/ScreenshotsCard.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,gBAAgB;IAChB,4BAA4B;IAC5B,aAAa;IACb,iCAAiC;AACrC;;AAEA;IACI,SAAS;AACb;;AAEA;IACI,4BAA4B;IAC5B,0BAA0B;AAC9B;;AAEA;;IAEI,4BAA4B;AAChC;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,+BAA+B;AACnC;;AAEA;IACI,8BAA8B;AAClC","sourcesContent":[".addScreenshotHeader {\n    font-size: 16px;\n    font-weight: 500;\n    color: var(--colors-grey800);\n    margin-top: 0;\n    margin-bottom: var(--spacers-dp4);\n}\n\n.addScreenshotDescription {\n    margin: 0;\n}\n\n.screenshotsGuidelinesLink {\n    color: var(--colors-blue700);\n    text-decoration: underline;\n}\n\n.screenshotsGuidelinesLink:hover,\n.screenshotsGuidelinesLink:focus {\n    color: var(--colors-grey900);\n}\n\n.hidden {\n    display: none;\n}\n\n.uploadButton {\n    margin-top: var(--spacers-dp16);\n}\n\n.deleteButton {\n    margin-top: var(--spacers-dp8);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addScreenshotHeader": "D8QmLScwmXItsL1Kydqw",
	"addScreenshotDescription": "d7NEVzHxSMllnCEZR8Ly",
	"screenshotsGuidelinesLink": "bGwoskSz1XGdCAtQ_Aec",
	"hidden": "XsYdjVGgOyl6KjyAUZxv",
	"uploadButton": "I1RJte0wWHAcrvgbR8Ec",
	"deleteButton": "s93SqPAWS9343Zbqu91F"
};
export default ___CSS_LOADER_EXPORT___;
