// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".hf4yvciu7VYCCjJDsvvc {\n    display: inline-block;\n    margin-right: var(--spacers-dp24);\n}\n", "",{"version":3,"sources":["webpack://./src/components/Versions/ChannelCheckbox/ChannelCheckbox.module.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,iCAAiC;AACrC","sourcesContent":[".checkboxContainer {\n    display: inline-block;\n    margin-right: var(--spacers-dp24);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkboxContainer": "hf4yvciu7VYCCjJDsvvc"
};
export default ___CSS_LOADER_EXPORT___;
