// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wzXtniCNSjl6rr87IHBw {\n    display: block !important;\n    max-width: 960px;\n    margin: auto;\n    margin-bottom: var(--spacers-dp24);\n    padding: var(--spacers-dp16);\n}\n\n.vwDcsRxGOKRJR5v2jabb {\n    font-size: 18px;\n    font-weight: 500;\n    margin-top: 0;\n    margin-bottom: var(--spacers-dp12);\n}\n", "",{"version":3,"sources":["webpack://./src/pages/UserApp/UserApp.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,gBAAgB;IAChB,YAAY;IACZ,kCAAkC;IAClC,4BAA4B;AAChC;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,aAAa;IACb,kCAAkC;AACtC","sourcesContent":[".card {\n    display: block !important;\n    max-width: 960px;\n    margin: auto;\n    margin-bottom: var(--spacers-dp24);\n    padding: var(--spacers-dp16);\n}\n\n.cardHeader {\n    font-size: 18px;\n    font-weight: 500;\n    margin-top: 0;\n    margin-bottom: var(--spacers-dp12);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "wzXtniCNSjl6rr87IHBw",
	"cardHeader": "vwDcsRxGOKRJR5v2jabb"
};
export default ___CSS_LOADER_EXPORT___;
