// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GzZ73BseN_YB_YOTi7xJ {\n    background: rgb(39, 102, 150) !important;\n}\n\n.SvxTT3B9b2MW4aL55VkU.SvxTT3B9b2MW4aL55VkU.LvCF5SIo3r1UCX6_aP3Q\n    .GzZ73BseN_YB_YOTi7xJ\n    .Wb7QulTz14_0zYYSs_6A {\n    opacity: 0 !important;\n}\n\n.SvxTT3B9b2MW4aL55VkU.SvxTT3B9b2MW4aL55VkU .z8bFp3iMTVFcxE9ZTdty {\n    color: #ffffff !important;\n}\n", "",{"version":3,"sources":["webpack://./src/styles/auth0-overrides.css"],"names":[],"mappings":"AAAA;IACI,wCAAwC;AAC5C;;AAEA;;;IAGI,qBAAqB;AACzB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".auth0-lock-header-bg {\n    background: rgb(39, 102, 150) !important;\n}\n\n.auth0-lock.auth0-lock.auth0-lock-opened\n    .auth0-lock-header-bg\n    .auth0-lock-header-bg-blur {\n    opacity: 0 !important;\n}\n\n.auth0-lock.auth0-lock .auth0-lock-header {\n    color: #ffffff !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"auth0-lock-header-bg": "GzZ73BseN_YB_YOTi7xJ",
	"auth0-lock": "SvxTT3B9b2MW4aL55VkU",
	"auth0-lock-opened": "LvCF5SIo3r1UCX6_aP3Q",
	"auth0-lock-header-bg-blur": "Wb7QulTz14_0zYYSs_6A",
	"auth0-lock-header": "z8bFp3iMTVFcxE9ZTdty"
};
export default ___CSS_LOADER_EXPORT___;
