// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!./styles/breakpoints.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["m-medium"] + " {\n    .ABl58jxzlqGOVD7zNCOf {\n        padding: var(--spacers-dp16);\n    }\n}\n", "",{"version":3,"sources":["webpack://./src/AppHub.module.css"],"names":[],"mappings":"AAEA;IACI;QACI,4BAA4B;IAChC;AACJ","sourcesContent":["@value m-medium from 'src/styles/breakpoints.css';\n\n@media m-medium {\n    .main {\n        padding: var(--spacers-dp16);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"m-medium": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["m-medium"] + "",
	"main": "ABl58jxzlqGOVD7zNCOf"
};
export default ___CSS_LOADER_EXPORT___;
